import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Hero from "../components/Hero"
import Layout from "../components/Layout"

export default class about extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <Layout colorScheme="black">
        <Helmet
          bodyAttributes={{
            class: "background-white",
          }}
        />
        <Hero
          title="Creators of digital"
          titleGradient="experiences"
          text={`We're a diverse team from Voorburg, consisting of professionals at the top of their game, with an expertise in design and development`}
          color="black"
        />
      </Layout>
    )
  }
}
